.footer-section {
  background: #191919;
  padding-top: 80px;
  padding-bottom: 50px;
}

.footer-option {
  margin-bottom: 20px;
}

.footer-option .fo-logo {
  margin-bottom: 30px;
}

.footer-option .fo-logo a {
  display: inline-block;
}

.footer-option ul {
  margin-bottom: 12px;
}

.footer-option ul li {
  list-style: none;
  font-size: 16px;
  color: #b3b3b3;
  line-height: 30px;
}

.footer-option .fo-social a {
  display: inline-block;
  font-size: 16px;
  color: #000000;
  width: 41px;
  height: 41px;
  background: #fef302;
  border-radius: 50%;
  text-align: center;
  line-height: 41px;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  margin-right: 3px;
}

.footer-option .fo-social a:hover {
  background: #01b3fd;
}

.footer-widget {
  margin-bottom: 30px;
}

.footer-widget.fw-links {
  padding-left: 35px;
}

.footer-widget h5 {
  font-size: 20px;
  color: #ffffff;
  font-weight: 700;
  margin-bottom: 20px;
}

.footer-widget ul li {
  list-style: none;
}

.footer-widget ul li a {
  font-size: 16px;
  color: #b3b3b3;
  line-height: 36px;
}

.footer-widget p {
  margin-bottom: 18px;
}

.footer-widget .news-form input {
  font-size: 14px;
  height: 46px;
  color: #b3b3b3;
  border: 1px solid #707070;
  padding-left: 25px;
  background: #191919;
  width: 100%;
  margin-bottom: 10px;
}

.footer-widget .news-form input::-webkit-input-placeholder {
  color: #b3b3b3;
}

.footer-widget .news-form input::-moz-placeholder {
  color: #b3b3b3;
}

.footer-widget .news-form input:-ms-input-placeholder {
  color: #b3b3b3;
}

.footer-widget .news-form input::-ms-input-placeholder {
  color: #b3b3b3;
}

.footer-widget .news-form input::placeholder {
  color: #b3b3b3;
}

.footer-widget .news-form button {
  font-size: 16px;
  color: #000000;
  font-weight: 700;
  display: block;
  background: #fef302;
  border: none;
  padding: 12px 0;
  width: 100%;
}
.footer-widget .news-form button:hover {
  font-size: 17px;
  background: #01b3fd;
}
.copyright-text {
  padding-top: 25px;
  color: #b3b3b3;
  text-align: center;
  font-size: 14px;
}

.copyright-text i {
  color: #39ff14;
}

.copyright-text a {
  color: #b3b3b3;
}

.copyright-text a:hover {
  color: #fef302;
}

@media screen and (max-width: 600px) {
  .footer-section {
    background: #191919;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: medium;
  }
  .footer-option .fo-social a {
    font-size: 12px;
    width: 35px;
    height: 35px;
    line-height: 35px;
  }
  .footer-widget {
    margin-bottom: 30px;
  }

  .footer-widget.fw-links {
    padding-left: 35px;
  }

  .footer-widget h5 {
    font-size: 15px;
    color: #ffffff;
    font-weight: 700;
    margin-bottom: 10px;
  }

  .footer-widget ul li {
    list-style: none;
  }

  .footer-widget ul li a {
    font-size: 13px;
    line-height: 26px;
  }

  .footer-widget p {
    margin-bottom: 5px;
  }

  .footer-widget .news-form input {
    font-size: 12px;
    height: 36px;
    margin-bottom: 5px;
  }

  .footer-widget .news-form button {
    font-size: 13px;
    padding: 10px 0;
  }

  .footer-widget .insta-pic img {
    margin-right: 5px;
    width: calc(33.33% - 5px);
    margin-bottom: 1px;
  }
}

@media screen and (min-width: 610px) and (max-width: 920px) {
  .footer-section {
    background: #191919;
    padding-top: 50px;
    padding-bottom: 20px;
  }
}
