.hero-section {
  height: 800px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-image: url(../../Assets/img/hero-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}

.hs-text span {
  color: red;
  font-size: 0.9em;
  font-weight: 700;
  text-transform: uppercase;
}

.hs-text h2 {
  color: #01b3fd;
  font-size: 60px;
  font-weight: 700;
  margin-top: 30px;
  margin-bottom: 10px;
}

.hs-text p {
  color: #ffffff;
  text-shadow: 2px 2px black;
  font-weight: 600;
  margin-bottom: 25px;
}

.mint {
  color: black;
  background-color: #fef302;
  border-width: 2px;
  border-style: outset;
  border-color: lightyellow;
  font-weight: bold;
  cursor: pointer;
  padding: 0.8em 1em;
}

.mint:hover {
  font-weight: bolder;
  color: #01b3fd;
}

@media screen and (max-width: 600px) {
  .hero-section {
    height: 500px;
    background-image: url(../../Assets/img/hero-bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
  }
  .hs-text span {
    font-size: 0.9em;
    font-weight: 700;
  }

  .hs-text h2 {
    font-size: 40px;
    font-weight: 700;
    margin-top: 10px;
    margin-bottom: 5px;
  }

  .hs-text p {
    margin-bottom: 15px;
    padding-right: 3em;
  }
  .hs-text p {
    margin-bottom: 40px;
  }
}
