#prt1 {
  background-image: url("../../Assets/img/blog/blog-1.jpg");
}
#prt2 {
  background-image: url("../../Assets/img/blog/blog-2.jpg");
}

.blog-section .container .row .col-lg-6 {
  color: rgb(0, 0, 0);
}

@media screen and (max-width: 600px) {
  #prt1 {
    background-image: none;
    height: auto;
  }
  #prt2 {
    height: auto;
    background-image: none;
  }
  .blog-item {
    margin-bottom: 3em;
  }
}
