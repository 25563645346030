.nav {
  background-color: #01b3fd;
  color: #fef302;

  position: fixed;
  width: 100%;
  z-index: 100;
}
.nav-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em 4em;
  width: 100%;
}

.nav-wrapper .logo {
  text-align: left;
}
.nav-wrapper .log {
  text-align: right;
}
.menu {
  display: none;
}

.links {
  display: flex;
  align-items: center;
}

.links > * {
  margin-left: 2.8em;
  font-weight: 600;
  cursor: pointer;
}

.links a:nth-child(4) {
  background-color: #fef302;
  padding: 0.6em 2.5em;
  border: none;
  border-radius: 8px;
  color: #01b3fd;
  font-weight: bold;
  border-width: 2px;
  border-style: ridge;
  border-color: #01b3fd;
}

.links a:nth-child(4):hover {
  background-color: #2abbf9;
  padding: 0.7em 2.5em;
  border-color: #fef302;
}

@media screen and (max-width: 960px) {
  .nav-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1em 2em;
  }

  .nav-wrapper .logo a img {
    width: 100px;
  }

  .links > a {
    margin-left: 2.3em;
  }

  .links a:nth-child(4) {
    margin-right: 1em;
  }
}

@media screen and (max-width: 600px) {
  .nav-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0.5em 1em;
  }

  .nav-wrapper .logo a img {
    width: 100px;
    margin-left: 0em;
    margin-top: -5px;
  }

  .menu {
    display: block;
    font-size: 1.3em;
    color: rgb(32, 32, 32);
  }

  .links {
    display: none;
  }

  .active-links {
    position: absolute;
    top: 50px;
    right: 0;
    width: 50vw;
    height: 35vh;
    padding: 1em 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.692);
    background-color: #01b3fd;
    text-decoration: underline;
    cursor: pointer;
  }

  .active-links > a {
    font-size: 1.4em;
    font-weight: 600;
  }

  .active-links a:nth-child(4) {
    padding: 0.2em 2.6em;
    border: none;
    border-radius: 1px;
  }
}
