.mintsection {
  background-color: black;
}
.blue {
  color: rgba(0, 0, 0, 1);
}

.closed {
  text-decoration: line-through;
}
.welcome {
  display: flex;
  flex-direction: column;
  max-width: 440px;
  margin: auto;
  text-align: center;
  line-height: 1.1em;
  font-weight: bold;
  color: rgb(49, 49, 153);
}

.welcome .head {
  font-size: 1.5em;
  line-height: 1.4em;
  margin-bottom: 0.6em;
  color: brown;
}

.gif {
  max-width: 300px;
  margin: auto;
}

.butt-val {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.butt-val button {
  padding: 0.9em 2em;
  border-radius: 50px;
  border: none;
  background-color: #01b3fd;
  color: #fef302;
  font-size: 17px;
  font-weight: bold;
  cursor: pointer;
}

.butt-val input {
  padding: 0.7em 5px;
  border-radius: 10px;
  border: 1.5px solid rgb(121, 121, 121);
  background-color: #01b1fda2;
  color: #fef302;
  font-size: 17px;
  font-weight: bold;
}

.mint-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1em;
  margin: auto;
  padding: 0 2em;
  margin-top: 3em;
  border-radius: 15px;
  box-shadow: 0 1px 15px 0px rgba(0, 0, 0, 1);
}

.slice {
  padding: 0 5px 0.5em 10px;
  background: #222222;
  text-align: center;
  margin: 1em;
  width: 300px;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.innerslice {
  height: 300px;
  width: 100%;
  background-size: cover;
}
.innerslice.one {
  background-image: url("../../Assets/img/mintsec/img1.jpg");
}
.innerslice.two {
  background-image: url("../../Assets/img/mintsec/img2.jpg");
}
.innerslice.three {
  background-image: url("../../Assets/img/mintsec/img3.png");
}
.action {
  padding-top: 1em;
}
.blockicon {
  font-size: 3em;
  height: 1em;
  padding-right: 0%;
}

.primary-btn {
  padding: 0 0 0 1em;
}

.overlay {
  position: fixed; /* Sit on top of the page content */
  display: none; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.ovcard {
  z-index: 10;
  margin: 10em 30em;
  margin: auto;
  margin-top: 7em;
  width: max-content;
  padding-top: 1em;
  background-color: rgba(0, 0, 0, 0);
}

.ovmodal {
  z-index: 10;
  margin: 10em 30em;
  margin: auto;
  width: 250px;
  width: max-content;
  padding: 1em 3em;
  background-color: white;
  border-radius: 7px;
  box-shadow: 0 1px 8px 0px rgba(0, 0, 0, 1);
}

.ovmodal-contents .ovhead {
  display: flex;
  padding-bottom: 2em;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
}

.ovmodal-contents .ovhead p {
  color: red;
  font-size: 20px;
  margin-left: 1em;
}

.ovmodal-btn {
  border: 1px solid #84b0c3;
  background-color: #84b0c3;
  color: white;
  border-radius: 5px;
  margin: 1em 0;
  padding: 0.4em 1.7em;
  font-size: 18px;
  cursor: pointer;
  font-weight: bold;
}

.tokenmint {
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-top: 3em;
  width: 30vw;
  max-height: min-content;
  padding: 2em;
  border-radius: 50px;
  background-color: #e3e9ecf7;
  box-shadow: 0 1px 8px 0px rgba(0, 0, 0, 1);
}

.tokenmint img {
  height: 50%;
  width: 50%;
  margin: auto;
}

.tokenmint .head {
  font-size: 2em;
  padding: 0.5em;
  font-weight: 400;
  margin: auto;
}

.tokenmint .contents {
  margin: auto;
  max-width: max-content;
  overflow: hidden;
}

.tokenmint .explorer {
  width: 15em;
  font-size: 20px;
  margin: auto;
  margin-top: 10px;
  padding: 0.5em 1em;
  background-color: #01b3fd;
  border-radius: 20px;
  border-color: #fef302;
}

.tokenmint .explorer a {
  color: white;
}

.tokenmint .close {
  margin: 10px 5em;
  padding: 1em 2em;
  font-weight: bolder;
  font-size: large;
  background-color: white;
  border-radius: 50px;
  border: none;
  color: #7390a1;
  cursor: pointer;
}

.ovwallet p {
  color: black;
  font-weight: 600;
}

#tez-btns button {
  border-radius: 10px;
}

input[type="number"] {
  position: relative;
  font-weight: bold;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: inner-spin-button important;
  width: 15px;
  position: absolute;
  top: 0;
  margin-top: 6px;
  right: 10px;
  height: 70%;
  background-color: gray;
}

@media screen and (min-width: 350px) and (max-width: 600px) {
  .mint-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2em;
    max-width: 80vw;
    margin: auto;
    padding: 3em 2em;
    margin-top: 3em;
    border-radius: 15px;
    box-shadow: 0 1px 15px 0px rgba(0, 0, 0, 1);
  }

  .welcome {
    display: flex;
    flex-direction: column;
    gap: 1em;
    max-width: 440px;
    margin: auto;
    line-height: 1.7em;
  }

  .welcome .head {
    font-size: 1.3em;
  }

  .head-2 {
    font-size: 1em;
  }

  .gif {
    max-width: 70vw;
    margin: auto;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .mintbutton-value {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .contract-details {
    max-width: 80vw;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 1.3em;
  }

  .address {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }

  .ovmodal {
    margin-top: 30vh;
    color: black;
  }
}

@media screen and (min-width: 620px) and (max-width: 920px) {
  .mint-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2em;
    max-width: 80vw;
    margin: auto;
    padding: 3em 2em;
    margin-top: 3em;
    border-radius: 15px;
    box-shadow: 0 1px 15px 0px rgba(0, 0, 0, 1);
  }

  .welcome {
    display: flex;
    flex-direction: column;
    gap: 1em;
    max-width: 440px;
    margin: auto;
    line-height: 1.7em;
  }

  .welcome .head {
    font-size: 1.7em;
  }

  .head-2 {
    font-size: 1.2em;
  }

  .gif {
    max-width: 80vw;
    margin: auto;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .gif img {
    height: 350px;
    width: 350px;
  }
}

.modal {
  margin: 10em 30em;
  width: 250px;
  height: 250px;
  padding: 0 1em;
  color: black;
  background-color: white;
  border-radius: 7px;
  box-shadow: 0 1px 8px 0px rgb(247, 240, 240);
}

.modal-contents .head {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: left;
}

.modal-contents .head p {
  color: red;
  text-align: right;
  font-weight: bold;
  font-size: 20px;
  margin-right: -2em;
  margin-top: -1em;
}
.modal-btn {
  border: 1px solid #84b0c3;
  background-color: #84b0c3;
  color: white;
  border-radius: 5px;
  padding: 0.4em 1.7em;
  font-size: 18px;
  cursor: pointer;
  font-weight: bold;
}

.wallet {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  margin-top: 1em;
}

.wallet > div {
  cursor: pointer;
}

@media screen and (max-width: 1028px) {
  .modal {
    margin: 10em 18em;
  }

  .modal-btn {
    margin-top: 1.1em;
  }

  .tokenmint {
    padding: auto;
    margin-top: 5em;
    width: 45vw;
    max-height: 50vh;
    border-radius: 50px;
    background-color: #e3e9ecf7;
    box-shadow: 0 1px 8px 0px rgba(0, 0, 0, 1);
  }

  .tokenmint img {
    width: 30%;
  }
}

@media screen and (max-width: 600px) {
  .ovcard {
    width: 100vw;
    z-index: 10;
    margin: auto;
    padding: 0;
    margin-top: 2em;
    width: max-content;
    padding-top: 1em;
    margin-bottom: 2em;
    text-align: left;
  }

  .modal {
    margin: 10em 4em;
    width: 200px;
    height: 200px;
    padding: 0 1em;
  }
  .modal-contents .head p {
    margin-left: 0.5em;
    margin-right: -1.3em;
    margin-top: 0.2em;
  }

  .mint-container .slice {
    padding: 0;
    margin: 0.1em;
    width: 503 0px;
  }

  .innerslice {
    height: 200px;
  }

  .blockicon {
    font-size: 2em;
    height: 1.2em;
    padding-right: 0%;
  }

  .tokenmint {
    margin-top: 2em;
    width: 85vw;
    max-height: min-content;
    border-radius: 20px;
  }

  .tokenmint .contents .explorer {
    width: 15em;
    font-size: 20px;
    margin: auto;
    margin-top: 10px;
    padding: 0.5em 1em;
  }
}
