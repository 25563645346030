.project {
  padding: 1em;
  background-color: black;
}

.about-cont {
  padding-top: 1em;
  max-width: 900px;
  margin: auto;
  text-align: center;
}

.about-cont > .head {
  font-size: 2.7em;
  font-weight: bold;
  text-shadow: 3px 4px 2px rgba(0, 0, 0, 1);
}

.box {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  gap: 2em;
  max-width: 700px;
  margin: auto;
  padding: 1em;
  margin-top: 1em;
  border-radius: 15px;
  box-shadow: 0 1px 15px 0px rgba(0, 0, 0, 1);
}

.details {
  font-weight: bold;
  font-size: 16px;
  color: rgb(213, 213, 224);
  padding-bottom: 1em;
}
.italic {
  font-style: italic;
}
.reduced {
  font-size: 14px;
}
.details h3 {
  font-weight: bold;
  padding-top: 0.7em;
}

.neon {
  color: #01b3fd;
}

@media screen and (min-width: 350px) and (max-width: 600px) {
  .project {
    padding-top: 7em;
    padding-bottom: 0;
    margin-bottom: 0;
  }

  .about-cont {
    max-width: 90vw;
    margin: auto;
    text-align: center;
  }

  .about-cont > .head {
    font-size: 2em;
  }

  .box {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2em;
    max-width: 90vw;
    margin: auto;
    padding: 2em 1em;
    margin-top: 3em;
  }
}
@media screen and (min-width: 620px) and (max-width: 920px) {
  .project {
    padding-top: 7em;
  }

  .about-cont {
    max-width: 90vw;
    margin: auto;
    text-align: center;
  }

  .about-cont > .head {
    font-size: 2em;
  }

  .box {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2em;
    max-width: 90vw;
    margin: auto;
    padding: 2em 1em;
    margin-top: 3em;
  }
}
