#port1 {
  background-image: url("../../Assets/img/portfolio/portfolio-1.jpg");
}
#port2 {
  background-image: url("../../Assets/img/portfolio/portfolio-2.png");
}
#port3 {
  background-image: url("../../Assets/img/portfolio/portfolio-3.png");
}
#port4 {
  background-image: url("../../Assets/img/portfolio/portfolio-4.png");
}
#port5 {
  background-image: url("../../Assets/img/portfolio/portfolio-5.png");
}

@media screen and (max-width: 600px) {
  .spin {
    width: 100vw;
    padding: 0;
  }

  .mobileflex {
    max-width: 100vw;
    padding-bottom: 0;
    margin-bottom: 0;
    display: flex;
    flex-direction: row;
  }

  .spin .col-md-6 {
    max-width: 50vw;
    padding: 0;
    margin: 0;
  }

  .portfolio-item {
    background-size: cover;
    width: 50vw;
    height: 200px;
    margin: 0;
    padding: 0;
  }

  #port2 {
    background-image: url("../../Assets/img/portfolio/portfolio-22.png");
  }
  #port3 {
    background-image: url("../../Assets/img/portfolio/portfolio-1.jpg");
  }
  #port1 {
    display: none;
  }
  #port4 {
    display: none;
  }
  #port5 {
    display: none;
  }
}
