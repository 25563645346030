.testimonial-section {
  background: #191919;
  padding-top: 2em;
}

.testimonial-slider {
  margin-left: 0;
}

.testimonial-slider .col-lg-6 {
  max-width: 100%;
}

.testimonial-slider.owl-carousel .owl-dots {
  text-align: center;
  padding-top: 22px;
}

.owl-theme button:last-child {
  margin-right: 0;
}

.testimonial-item {
  background: #222222;
  padding: 10px 5px;
}

.testimonial-item .ti-pic {
  float: left;
  margin: 10px;
}

.testimonial-item .ti-pic img {
  height: 100px;
  width: 100px;
  border-radius: 50%;
}

.testimonial-item .ti-text {
  overflow: hidden;
}

.testimonial-item .ti-text .ti-title {
  margin-bottom: 8px;
}

.testimonial-item .ti-text .ti-title h4 {
  font-size: 22px;
  color: #ffffff;
  font-weight: 700;
  margin-bottom: 4px;
}

.testimonial-item .ti-text .ti-title span {
  font-size: 12px;
  color: #39ff14;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.testimonial-item .ti-text p {
  margin-bottom: 0;
  max-height: 80px;
  text-overflow: clip;
  overflow-y: hidden;
}

.testimonial-item .ti-text p:hover {
  max-height: max-content;
}

.content {
  text-overflow: clip;
}

@media screen and (max-width: 900px) {
  OwlCarousel {
    items: 1;
    display: none;
  }

  .testimonial-section {
    background: #191919;
    padding-top: 2em;
  }

  .testimonial-slider .col-lg-6 {
    max-width: 100%;
  }

  .testimonial-slider.owl-carousel .owl-dots {
    padding-top: 12px;
  }

  .testimonial-item .ti-pic {
    margin-right: 0px;
  }

  .testimonial-item .ti-pic img {
    height: 50px;
    width: 50px;
    border-radius: 50%;
  }

  .testimonial-item .ti-text .ti-title {
    margin-bottom: 5px;
  }

  .testimonial-item .ti-text .ti-title h4 {
    font-size: 20px;
    margin-bottom: 2px;
  }

  .ti-text p {
    font-size: small;
  }
}

@media screen and (max-width: 600px) {
  OwlCarousel {
    items: 1;
    display: none;
  }

  .testimonial-section {
    background: #191919;
    padding-top: 2em;
  }

  .testimonial-slider .col-lg-6 {
    max-width: 100%;
  }

  .testimonial-slider.owl-carousel .owl-dots {
    padding-top: 12px;
  }

  .testimonial-item {
    padding: 3px 12px;
  }

  .testimonial-item .ti-pic {
    margin-right: 0px;
  }

  .testimonial-item .ti-pic img {
    height: 50px;
    width: 50px;
    border-radius: 50%;
  }

  .testimonial-item .ti-text .ti-title {
    margin-bottom: 5px;
  }

  .testimonial-item .ti-text .ti-title h4 {
    font-size: 20px;
    margin-bottom: 2px;
  }

  .ti-text p {
    font-size: small;
  }
}
