.about-us-section {
  background: #191919;
  background-color: black;
}

.as-pic img {
  min-width: 100%;
}

.as-text {
  padding-top: 30px;
  padding-left: 20px;
}

.as-text.ap-text {
  padding-top: 14px;
}

.as-text .section-title {
  text-align: left;
  margin-bottom: 15px;
}

.as-text p {
  color: #b3b3b3;
}

.as-text p.f-para {
  margin-bottom: 10px;
}

.as-text .about-counter .ac-item {
  float: left;
  margin-right: 48px;
  position: relative;
}

.as-text .about-counter .ac-item:after {
  position: absolute;
  right: -20px;
  top: 8px;
  height: 65px;
  width: 1px;
  background: #393939;
  content: "";
}

.as-text .about-counter .ac-item:last-child {
  margin-right: 0;
  padding-right: 0;
}

.as-text .about-counter .ac-item:last-child:after {
  display: none;
}

.as-text .about-counter .ac-item h2 {
  color: #ffffff;
  font-weight: 700;
  margin-bottom: 10px;
}

.as-text .about-counter .ac-item p {
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 0;
}

.videosec {
  padding: 5em 10em 0 10em;
  width: 100%;
  height: 30em;
}

.quote {
  color: #fef302;
  font-size: 2em;
}

@media screen and (max-width: 600px) {
  .spad {
    padding-top: 5em;
    padding-bottom: 2em;
  }

  .row {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .as-text {
    font-size: small;
    padding-top: 10px;
    padding-left: 20px;
    text-align: left;
  }

  .section-title h2 {
    font-size: x-large;
    font-weight: 900;
  }

  .as-text p.f-para {
    margin-bottom: 10px;
    font-size: medium;
  }

  .col-lg-6 {
    margin-left: 0;
    width: 90vw;
  }

  .as-text .about-counter .ac-item {
    float: left;
    margin-right: 48px;
    position: relative;
  }

  .as-text .about-counter .ac-item:after {
    position: absolute;
    right: -20px;
    top: 8px;
    height: 65px;
    width: 1px;
    background: #393939;
    content: "";
  }

  .as-text .about-counter .ac-item h2 {
    margin-bottom: 5px;
  }

  .as-text .about-counter .ac-item p {
    letter-spacing: 1px;
    margin-bottom: 0;
  }

  .videosec {
    padding: 1em 2em;
    padding-bottom: 0;
    width: 100%;
  }
}
