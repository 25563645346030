#altcaro {
  display: none;
}
#calltoaction {
  background-image: url("../../Assets/img/ctc-bg.jpg");
}
#mem1 {
  background-image: url("../../Assets/img/member/mem1.jpg");
}
#mem2 {
  background-image: url("../../Assets/img/member/mem2.png");
}

#mem3 {
  background-image: url("../../Assets/img/member/mem3.png");
}

#mem4 {
  background-image: url("../../Assets/img/member/mem4.png");
}

.yellow {
  color: #fef302;
  font-size: 1.2em;
  padding-top: 2em;
}

.blue {
  color: #01b3fd;
  font-size: 1.1em;
  padding-top: 2em;
}

.yellowblue {
  color: red;
  font-size: 1.2em;
  padding-top: 2em;
}

.container {
  text-justify: center;
}

.invite {
  padding: 0.6em 1em;
  border-style: solid;
  border: 2px solid #01b3fd;
  border-radius: 7px;
}
@media only screen and (min-width: 950px) {
  .col-lg-4,
  .member-item {
    background-size: cover;
    width: 20em;
  }
}

@media only screen and (min-width: 600px) and (max-width: 950px) {
  .col-lg-4,
  .member-item {
    background-size: cover;
    width: 200px;
  }
}

@media only screen and (max-width: 600px) {
  #carousel {
    display: none;
  }
  #altcaro {
    display: contents;
  }

  .mi-tex {
    padding-top: 2em;
  }

  .mi-tex .mt-title h4 {
    color: white;
  }

  .mi-tex .mt-social {
    margin-top: 1em;
  }
  .mi-tex .mt-social i {
    background-color: #fef302;
    padding: 0.5em;
    margin: 0.51em;
    border-radius: 10px;
    color: black;
  }
}
